<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <div>
                <h4 class="title">Akun Perkiraan</h4>
              </div>
            </div>

            <div class="button-edit" v-show="validated == 1">
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div class="" v-show="validated != 1">
              <button class="btn btn-save" type="submit" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>
          <div class="card-table">
            <div class="info-detail">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li
                  class="nav-item"
                  v-for="(value, key) in listAkun"
                  :key="key"
                  role="presentation"
                >
                  <a
                    class="nav-link"
                    :class="{ active: key == 0 }"
                    :id="`pills-${value.id}-tab`"
                    data-toggle="pill"
                    :href="`#pills-${value.id}`"
                    role="tab"
                    :aria-controls="`pills-${value.id}`"
                    aria-selected="true"
                    >{{ value.labelNav }}</a
                  >
                </li>
              </ul>

              <div class="tab-content mt-5" id="pills-tabContent">
                <div
                  v-for="(value, key) in listAkun"
                  :key="key"
                  class="tab-pane fade"
                  :class="{ 'show active': key == 0 }"
                  :id="`pills-${value.id}`"
                  role="tabpanel"
                  :aria-labelledby="`pills-${value.id}-tab`"
                >
                  <div class="row">
                    <div
                      class="col-7"
                      v-for="(item, index) in value.list"
                      :key="index"
                    >
                      <div class="form-group d-flex align-items-center">
                        <label class="form-label">{{ item.label }}</label>
                        <div class="w-100">
                          <Select2
                            style="width: 100%"
                            :disabled="validated == 1"
                            v-model="formData[item.name]"
                            :options="listOption[item.type]"
                            :placeholder="`Pilih ${item.label}`"
                            :settings="{ templateResult: formatState }"
                            @change="formChange(item.name)"
                            @select="formChange(item.name)"
                            :class="{
                              'is-invalid': formError && formError[item.name],
                            }"
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError[item.name]"
                          >
                            {{ formError[item.name] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
// import FooterDashboard from '../src/components/Footer.vue'
import Select2 from "vue3-select2-component";
import SidebarDashboard from "../../../../src/components/Sidebar.vue";
import NavbarDashboard from "../../../../src/components/Navbar.vue";
import { checkRules, cksClient, showAlert } from "../../../helper";
import $ from "jquery";
import { get_AkunList } from "../../../actions/akun_perkiraan";
import {
  get_listAccountSetting,
  post_saveBulkAccountSetting,
} from "../../../actions/accountSetting";
export default {
  name: "DashboardLayout",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
  },

  data() {
    return {
      validated: 1,
      id_company: cksClient().get("_account").id_company,
      formError: [],
      isSubmit: false,
      listAkun: [
        {
          labelNav: "Project",
          id: "project",
          list: [
            {
              label: "Akun Piutang",
              type: "piutang",
              name: "akun_piutang_project",
            },
            {
              label: "Akun Diskon",
              type: "beban",
              name: "akun_diskon_project",
            },
            {
              label: "Akun Beban Pajak",
              type: "pajak",
              name: "akun_pajak_project",
            },
            {
              label: "Akun Piutang Pajak",
              type: "piutang",
              name: "akun_piutang_pajak_project",
            },
            {
              label: "Akun Pendapatan",
              type: "pendapatan",
              name: "akun_pendapatan_project",
            },
            {
              label: "Akun Beban Fee Marketing",
              type: "beban",
              name: "akun_beban_fee_marketing",
            },
            {
              label: "Akun Hutang Fee Marketing",
              type: "hutang",
              name: "akun_hutang_fee_marketing",
            },
          ],
        },
        {
          labelNav: "Piutang Usaha",
          id: "piutang",
          list: [
            {
              label: "Akun Piutang Usaha",
              type: "piutang",
              name: "akun_piutang_usaha",
            },
            {
              label: "Akun Beban Pajak",
              type: "pajak",
              name: "akun_pajak_invoice",
            },
            {
              label: "Akun Piutang Pajak",
              type: "piutang",
              name: "akun_piutang_pajak_invoice",
            },
          ],
        },
        {
          labelNav: "Hutang Usaha",
          id: "hutang",
          list: [
            {
              label: "Akun Hutang Usaha",
              type: "hutang",
              name: "akun_hutang_usaha",
            },
            {
              label: "Hutang PPh",
              type: "hutang_pajak",
              name: "akun_hutang_pph",
            },
          ],
        },
        {
          labelNav: "Kasbon Karyawan",
          id: "kasbon",
          list: [
            {
              label: "Akun Kasbon Karyawan",
              type: "kasbon",
              name: "akun_kasbon_karyawan",
            },
          ],
        },
        {
          labelNav: "Barang",
          id: "barang",
          list: [
            {
              label: "Beban Pengeluaran Barang",
              type: "beban",
              name: "akun_beban_pengeluaran_barang",
            },
            {
              label: "Hutang Payment Order",
              type: "hutang",
              name: "akun_hutang_payment_order",
            },
            {
              label: "Beban Pajak PPh",
              type: "pajak",
              name: "akun_pajak_payment_order",
            },
            {
              label: "Ekuitas Saldo Awal",
              type: "modal",
              name: "akun_ekuitas_barang",
            },
            {
              label: "Akun Temporary",
              type: "all",
              name: "akun_temporary_barang",
            },
          ],
        },
        {
          labelNav: "Payroll",
          id: "payroll",
          list: [
            {
              label: "Akun Kas & Bank",
              type: "kasbank",
              name: "akun_payroll_bank",
            },
          ],
        },
        {
          labelNav: "Reimburse",
          id: "reimburse",
          list: [
            {
              label: "Akun Kas & Bank",
              type: "kasbank",
              name: "akun_reimburse_bank",
            },
            {
              label: "Akun Beban",
              type: "beban",
              name: "akun_reimburse_beban",
            },
          ],
        },
        {
          labelNav: "Akun Lainnya",
          id: "lainnya",
          list: [
            {
              label: "Akun Modal Awal",
              type: "modal",
              name: "akun_modal_awal",
            },
          ],
        },
      ],
      formData: {
        id_company: cksClient().get("_account").id_company,
        id_divisi: "",
        type: "akun_perkiraan",
        akun_piutang_project: "",
        akun_diskon_project: "",
        akun_pajak_project: "",
        akun_piutang_pajak_project: "",
        akun_pendapatan_project: "",
        akun_piutang_usaha: "",
        akun_pajak_invoice: "",
        akun_piutang_pajak_invoice: "",
        akun_hutang_usaha: "",
        akun_ekuitas_barang: "",
        akun_beban_pengeluaran_barang: "",
        akun_hutang_payment_order: "",
        akun_beban_fee_marketing: "",
        akun_hutang_fee_marketing: "",
        akun_pajak_payment_order: "",
        akun_hutang_pph: "",
        akun_payroll_bank: "",
        akun_reimburse_bank: "",
        akun_reimburse_beban: "",
        akun_temporary_barang: "",
        // akun_aset_penyustan: "",
        // akun_beban_penyusutan: "",
        akun_kasbon_karyawan: "",
        akun_modal_awal: "",
      },
      rules: {
        akun_piutang_project: {
          required: true,
        },
        akun_diskon_project: {
          required: true,
        },
        akun_pajak_project: {
          required: true,
        },
        akun_piutang_pajak_project: {
          required: true,
        },
        akun_pendapatan_project: {
          required: true,
        },
        akun_piutang_usaha: {
          required: true,
        },
        akun_pajak_invoice: {
          required: true,
        },
        akun_piutang_pajak_invoice: {
          required: true,
        },
        akun_hutang_pph: {
          required: true,
        },
        akun_hutang_usaha: {
          required: true,
        },
        akun_ekuitas_barang: {
          required: true,
        },
        akun_beban_pengeluaran_barang: {
          required: true,
        },
        akun_hutang_payment_order: {
          required: true,
        },
        akun_beban_fee_marketing: {
          required: true,
        },
        akun_hutang_fee_marketing: {
          required: true,
        },
        akun_pajak_payment_order: {
          required: true,
        },
        akun_payroll_bank: {
          required: true,
        },
        akun_reimburse_bank: {
          required: true,
        },
        akun_reimburse_beban: {
          required: true,
        },
        akun_temporary_barang: {
          required: true
        },
        // akun_aset_penyustan: {
        //   required: true,
        // },
        // akun_beban_penyusutan: {
        //   required: true,
        // },
        akun_kasbon_karyawan: {
          required: true,
        },
        akun_modal_awal: {
          required: true,
        },
      },
      listOption: {
        all: [],
        piutang: [],
        beban: [],
        pajak: [],
        pendapatan: [],
        hutang: [],
        hutang_pajak: [],
        aset_penyusutan: [],
        beban_penyusutan: [],
        kasbon: [],
        modal: [],
        kasbank: [],
      },
    };
  },

  created() {
    for (const i in this.listOption) {
      if (Object.hasOwnProperty.call(this.listOption, i)) {
        this.getAkun(i);
      }
    }
    this.getList();
  },

  methods: {
    getAkun(type = "") {
      var id_type = "";
      var not_type = false;
      if (type == "piutang") {
        id_type = "2,17";
      } else if (type == "beban") {
        id_type = "14,15,17";
      } else if (type == "pajak") {
        id_type = "8,9,14,15,19";
      } else if (type == "pendapatan") {
        id_type = "12,16";
      } else if (type == "hutang") {
        id_type = "8,9,10";
      } else if (type == "aset_penyusutan") {
        id_type = "6";
      } else if (type == "beban_penyusutan") {
        id_type = "14,15";
      } else if (type == "kasbon") {
        id_type = "1";
        not_type = true;
      } else if (type == "modal") {
        id_type = "11";
      } else if (type == "hutang_pajak") {
        id_type = "8,9,10,14,15,19";
      } else if (type == "kasbank") {
        id_type = "1";
      }
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: id_type,
          not_type: not_type,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          if (type != "") {
            this.listOption[type] = data;
          }
        }
      );
    },
    getList() {
      get_listAccountSetting(
        {
          id_company: this.id_company,
          type: "akun_perkiraan",
        },
        (res) => {
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (Object.hasOwnProperty.call(this.formData, element.nama)) {
                this.formData[element.nama] = element.nilai;
              }
            }
          }
        }
      );
    },

    editable() {
      this.validated = 2;
    },

    changeTab() {
      this.validated = 1;
    },
    clickCancel() {
      this.$router.push({ name: "dataCompany" });
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-4" : state.sub_level == 1 ? "pl-3" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    async formChange(key) {
      if (Object.hasOwnProperty.call(this.rules, key)) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },

    postData() {
      this.isSubmit = true;
      post_saveBulkAccountSetting(
        this.formData,
        () => {
          this.isSubmit = false;
          this.validated = 1;
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: "semua data berhasil diperbarui",
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali nanti",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "apakah anda yakin, Semua perubahan yang anda lakukan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Pastikan semua data terisi dengan benar sebelum menyimpan data",
          showCancelButton: true,
          showConfirmButton: false,
        });
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}
</style>
